.swiper {
  :global {
    .swiper-button-prev,
    .swiper-button-next {
      width: 32px !important;
      height: 32px !important;
      color: black;
      padding: 0 !important;
      border-radius: 50% !important;
      transition: all 0.3s !important;
      background-color: white !important;
      border: 1px solid rgba(0, 0, 0, 0.05) !important;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important;

      @media (max-width: 768px) {
        width: 24px !important;
        height: 24px !important;

        transform: translateY(50%) !important;
      }

      &.swiper-button-disabled {
        display: none !important;
      }

      &:hover {
        background-color: #efefef !important;
      }

      &::after {
        font-size: 16px !important;
      }
    }
  }
}
